import React, {FC} from 'react';
import logo from '../../assets/logo.png'

const LogoForm: FC = () => {
    return (
        <h2 className='logoCompany'>
            <img src={logo} alt='logo'/>
        </h2>
    )
}

export default LogoForm;