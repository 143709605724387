import React, {FC, useState} from 'react';
import Form from "react-bootstrap/Form";
import LoadingButton from "../buttons/loadingButton/LoadingButton";
import {useFetching} from "../../hooks/useFetching";
import {IAuthLogInParams, ITokens} from "../../entity/auth/AuthDto";
import AuthService from "../../entity/auth/AuthService";
import {NavigateFunction} from "react-router-dom";
import {useAppDispatch} from "../../redux/hooks";
import {setPersonalNumber} from "../../redux/reducers/profileSlice";
import LocalStorageService from "../../entity/storage/LocalStorageService";

interface AuthBasicFormProps {
    navigate: NavigateFunction,
    setTokens: (tokens: ITokens) => void
    forView: AuthBasicFormView
}

type AuthBasicFormView = "user" | "admin" | "redirectToReset"

type AuthType = "default" | "fromClient"

const AuthBasicForm: FC<AuthBasicFormProps> = ({navigate, setTokens, forView}) => {
    const dispatch = useAppDispatch()
    const [login, setLogin] = useState<string>("")
    const [typeAuth, setTypeAuth] = useState<AuthType>("default");
    const [password, setPassword] = useState<string>("")
    const [personal, setPersonal] = useState("");
    const [error, setError] = useState<string | null>(null)
    const [logIn, isLoading, authError] =
        useFetching<IAuthLogInParams>(async (params) => {
            const response = await AuthService.logIn(params);

            if (response.body) {
                const {refreshToken, token, personnelNumber} = response.body

                if (!personnelNumber) {
                    setError("Не найден табельный номер. Обратитесь к администратору.")
                }

                if (token && refreshToken && personnelNumber) {
                    LocalStorageService.setValue('personnelNumber', personnelNumber)
                    dispatch(setPersonalNumber(personnelNumber))
                    setTokens({refreshToken, token})

                    console.log(token, refreshToken, personnelNumber, forView)

                    if (forView === 'redirectToReset') {
                        console.log('redirect to reset')
                        navigate('/auth-reset')
                    } else navigate('/')
                }
            }
        })

    const logInHandle = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setError("")

        if (login && password)
            await logIn({
                login,
                password,
                personnelNumber: (typeAuth === "fromClient" && personal) ? personal : undefined
            })
        else setError("Введите логин и пароль")
    }

    const loginChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(e.target.value)
    }

    const passwordChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const personalNumberChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPersonal(e.target.value)
    }

    const switchTypeAuth = () => {
        setError(null)
        setTypeAuth(prevState => prevState === "default" ? "fromClient" : "default")
    }

    return (
        <>
            <Form className="form">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Логин</Form.Label>
                    <Form.Control type="text" placeholder="Введите логин" value={login} onChange={loginChangeHandle}/>
                </Form.Group>
                {
                    (typeAuth === "fromClient" && forView === "admin") &&
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label>Персональный номер</Form.Label>
                        <Form.Control type="text" placeholder="Введите персональный номер" value={personal}
                                      onChange={personalNumberChangeHandle}/>
                    </Form.Group>
                }
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control type="password" placeholder="Введите пароль" value={password}
                                  onChange={passwordChangeHandle}/>
                </Form.Group>
                <LoadingButton type="submit" classes={['primary']} title={'Войти'} titleProcessing={'Авторизация'}
                               onClick={logInHandle}/>
            </Form>
            {
                forView === "admin" && <a href="#type_auth" className="mt-1"
                                          onClick={switchTypeAuth}>{typeAuth === "fromClient" ?
                    "Войти по логину и паролю" : "Войти с учетки клиента"}</a>
            }
            <div className="mt-2">
                {
                    authError && <p className="text-center errorValidateBlock">{authError}</p>
                }
                {
                    error && <p className="text-center errorValidateBlock">{error}</p>
                }
            </div>
        </>
    );
}

export default AuthBasicForm;
