import {createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import {enableMapSet} from "immer"
import ClientForProfileDto from "../../entity/client/ClientForProfileDto";
import {IUserInfo} from "../../entity/auth/AuthDto";
import {getUserInfo} from "../actions/profileAsyncActions";
import {AbstractSate} from "./AbstractSate";
import LocalStorageService from "../../entity/storage/LocalStorageService";

enableMapSet()

const name = 'profile'

interface ProfileState extends AbstractSate {
    // userName: string
    personnelNumber: string
    clientData: ClientForProfileDto | undefined
    userInfo?: IUserInfo
}

const initialState: ProfileState = {
    isLoading: false,
    error: "",
    userInfo: undefined,
    clientData: undefined,
    // userName: '',
    personnelNumber: LocalStorageService.getValue('personnelNumber') ?? ""
}

export const profileSlice = createSlice({
    name,
    initialState,
    reducers: {
        setClientData: (state, action: PayloadAction<ClientForProfileDto | undefined>) => {
            state.isLoading = true
            state.clientData = action.payload

            const personnelNumber = action.payload?.personnelNumber
            if (personnelNumber)
                state.personnelNumber = personnelNumber

            state.isLoading = false
        },
        setUserInfo: (state, action: PayloadAction<IUserInfo>) => {
            state.userInfo = {...state.userInfo, ...action.payload}
        },
        setConfirmChangePassword: (state) => {
            if (state.userInfo)
                state.userInfo = {...state.userInfo, isTemporaryPassword: false}
        },
        setUserName: (state, action: PayloadAction<string>) => {
            // state.userName = action.payload
        },
        setPersonalNumber: (state, action) => {
            state.personnelNumber = action.payload
        },
        resetUserInfoData: (state, action) => {
            state.userInfo = undefined
        }
    },
    extraReducers: builder => {
        builder.addCase(getUserInfo.pending, ((state, action) => {
            state.isLoading = true
        })).addCase(getUserInfo.fulfilled, ((state, action) => {
            state.userInfo = action.payload
            state.isLoading = false
        })).addCase(getUserInfo.rejected, ((state, action) => {
            state.error = action.error.message
            state.isLoading = false
        }))
    }
})

export const {setClientData, setUserInfo, setConfirmChangePassword,
    resetUserInfoData, setPersonalNumber, setUserName} = profileSlice.actions
export default profileSlice.reducer

