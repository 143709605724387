import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import DateUtils from "../utils/DateUtils";
import AuthService from "../entity/auth/AuthService";
import {IResponseTokens, ITokens} from "../entity/auth/AuthDto";
import LocalStorageService from "../entity/storage/LocalStorageService";

const {REACT_APP_HOST_BACKEND_DEV, REACT_APP_HOST_BACKEND_PROD, REACT_APP_STAGE,} = process.env;
let HOST_BACKEND = REACT_APP_STAGE === "DEV" ? REACT_APP_HOST_BACKEND_DEV : REACT_APP_HOST_BACKEND_PROD;

const blackListLinks = [
    '/auth',
    '/recovery'
];

export const getTokensLocalStorage = (): ITokens | undefined => {
    const accessToken = LocalStorageService.getValue<string>('accessToken');
    const refreshToken = LocalStorageService.getValue<string>('refreshToken');

    if (accessToken && refreshToken)
        return {token: accessToken, refreshToken};
};

export const clearTokensLocalStorage = () => {
    LocalStorageService.remove('accessToken');
    LocalStorageService.remove('refreshToken');
    LocalStorageService.remove('personnelNumber');
};

export const setTokensLocalStorage = (tokens: ITokens | IResponseTokens | undefined) => {
    if (tokens) {
        const {token: accessToken, refreshToken} = tokens;
        LocalStorageService.setValue('accessToken', accessToken);
        LocalStorageService.setValue('refreshToken', refreshToken);

        const {personnelNumber} = tokens as IResponseTokens;
        if (personnelNumber)
            LocalStorageService.setValue('personnelNumber', personnelNumber);
    } else {
        clearTokensLocalStorage();
    }
};

export const authHeaders = (): any => {
    const tokens = getTokensLocalStorage();
    if (tokens) {
        const {token} = tokens;
        return token ? {Authorization: `Bearer ${token}`} : {};
    }

    return {};
};

export const authHeaderValue = (): string => {
    const tokens = getTokensLocalStorage();
    if (tokens) {
        const {token} = tokens;
        return token ? `Bearer ${token}` : "";
    }

    return "";
};

export const api = axios.create({
    baseURL: `${HOST_BACKEND}`,
});

function validateCurUrl(isRedirect: boolean = true) {
    const path = window.location.pathname;
    LocalStorageService.setValue('lastPath', path);

    if (isRedirect)
        window.location.replace(path.includes('auth') ? path : '/auth');
}

const refreshAuthLogic = (failedRequest: any) => {
    let tokens = getTokensLocalStorage();

    if (tokens?.refreshToken && !window.location.href.includes('auth')) {
        return AuthService.getTokens(tokens.refreshToken).then((tokenRefreshResponse) => {
            setTokensLocalStorage(tokenRefreshResponse.body);
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.body.token;
            return Promise.resolve();
        }).catch(async err => {
            clearTokensLocalStorage();
            validateCurUrl();
            return Promise.reject(err);
        });
    } else {
        validateCurUrl(false);

        if (!blackListLinks.some(link => window.location.href.includes(link))) {
            window.location.replace('/auth');
        }

        return Promise.reject(failedRequest);
    }
};

api.interceptors.request.use(async config => {
    const tokens = getTokensLocalStorage();

    if (tokens?.refreshToken && tokens?.token) {
        const headers = authHeaders();
        config.headers = {...config.headers, ...headers, "app": "web"};
    }

    return config;
});

api.interceptors.response.use(r => {
    DateUtils.handleDates(r);
    return r;
});

createAuthRefreshInterceptor(api, refreshAuthLogic, {statusCodes: [401, 403]});
