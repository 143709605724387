import React, {FC} from 'react';
import AuthBasicForm from "../../components/froms/AuthBasicForm";
import useTokens from "../../hooks/useTokens";
import {useNavigate} from "react-router-dom";

interface AuthBaseRedirectProps {

}

const AuthBaseRedirect: FC<AuthBaseRedirectProps> = ({}) => {
    const navigate = useNavigate();
    const {tokens, setTokens} = useTokens();

    return (
        <AuthBasicForm forView={'redirectToReset'} setTokens={setTokens} navigate={navigate}/>
    )
}

export default AuthBaseRedirect;