import React, {FC, useState} from "react";
import {Button, Spinner} from "react-bootstrap";

interface LoadingButtonProps {
    title: string
    titleProcessing?: string
    disabled?: boolean
    type?: 'submit' | 'reset' | 'button' | undefined;
    classes?: string[]
    onClick: (e?: any) => Promise<any> | void
}

const styles = {
    spinner: {
        border: 'var(--bs-spinner-border-width) solid currentcolor !important',
        borderRightColor: 'transparent !important'
    }
}

const LoadingButton: FC<LoadingButtonProps> = ({
                                                   title, titleProcessing = "Подождите...", classes=['btn-primary'],
                                                   type = "button", onClick, disabled
                                               }) => {
    const [isLoading, setLoading] = useState(false);

    const handleClick = async (e: any) => {
        setLoading(true)
        await Promise.race([onClick(e)]).finally(() => setLoading(false))
    };

    return (
        <>
            <Button className={`btn ${classes && classes.join(' ')}`}
                    disabled={isLoading || disabled} onClick={handleClick} type={type}>
                {
                    isLoading ? <>
                        <Spinner style={styles.spinner} animation="border" variant="light" size={"sm"}/>&nbsp;
                        {titleProcessing}</> : title
                }
            </Button>
        </>
    )
}

export default LoadingButton
