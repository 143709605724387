import React, {FC} from 'react';
import Button from "react-bootstrap/Button";

interface DesignProps {

}

const Design: FC<DesignProps> = ({}) => {
    return (
        <div style={{marginTop: 100}}>
            <h1>Заголовок 1</h1>
            <h2>Заголовок 2</h2>
            <h3>Заголовок 3</h3>
            <h4>Заголовок 4</h4>
            <h5>Заголовок 5</h5>
            <h6>Заголовок 6</h6>
            <br/>
            <br/>
            <h1 className="sub-head-1">Подзаголовок 1</h1>
            <h1 className="sub-head-med-1">Подзаголовок medium 1</h1>
            <h2 className="sub-head-2">Подзаголовок 2</h2>
            <h2 className="sub-head-med-2">Подзаголовок medium 2</h2>
            <h3 className="sub-head-3">Подзаголовок 3</h3>
            <h3 className="sub-head-med-3">Подзаголовок medium 3</h3>
            <br/>
            <br/>
            <label htmlFor="#">Label</label>
            <br/>
            <br/>
            <div className="body-1">Body 1</div>
            <div className="body-1 font-medium">Body 1 med</div>
            <div className="body-1 font-medium text-mono">Body 1 med mono</div>
            <div className="body-1 font-bold">Body 1 bold</div>
            <div className="body-1 font-bold text-mono">Body 1 bold mono</div>
            <br/>
            <br/>
            <div className="body-2">Body 2</div>
            <div className="body-2 font-medium">Body 2 med</div>
            <div className="body-2 font-medium text-mono">Body 2 med mono</div>
            <div className="body-2 font-bold">Body 2 bold</div>
            <div className="body-2 font-bold text-mono">Body 2 bold med</div>

            <h1>BUTTONS</h1>
            <div className="d-flex gap-4 flex-wrap">
                <Button className="btn btn-primary">btn-primary</Button>
                <Button className="btn btn-secondary">btn-secondary</Button>
                <Button className="btn btn-default">btn-default</Button>
                <Button className="btn btn-outline-secondary">btn-outline-secondary</Button>
                <Button className="btn btn-outline-primary">btn-outline-primary</Button>
                <Button className="btn outline-primary">outline-primary</Button>
            </div>

            <br/>
            <br/>
        </div>
    )
}

export default Design;