import React, {useEffect, useLayoutEffect, useState} from 'react';
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import RightMenu from "../components/toolbar/RightMenu";
import {Page, RFC} from "../common/types";
import "./layout.scss";
import Toolbar from "../components/toolbar";
import ConfirmDialogProvider, {useConfirmDialog} from "../components/dialogs/ConfirmDialogContext";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import Container from "react-bootstrap/Container";
import AuthService from "../entity/auth/AuthService";
import FullPageLoading from "../components/UI/loader/FullPageLoading";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    resetUserInfoData,
    setConfirmChangePassword,
    setPersonalNumber,
    setUserInfo,
    setUserName
} from "../redux/reducers/profileSlice";
import InfoCartBanner from "../components/banners/InfoCartBanner";
import ToolbarMenu from "../components/toolbar/ToolbarMenu";
import ErrorBlock from "../components/info/ErrorBlock";
import Center from "../components/UI/layouts/Center";
import Image from 'react-bootstrap/Image';
import ClientProfileService from '../entity/clientProfile/ClientProfileService';
import {setDivisions} from '../redux/reducers/divisionSlice';
import {DivisionSortType} from '../components/dropDown/division/DivisionDropdown';

interface IProps {
    pages: Page[];
}

interface LayoutState {
    isLoading: boolean;
    checkAuth: null | string;
}

const Layout: RFC<IProps> = ({pages}) => {
        const {currentDivisionRef} = useAppSelector(state => state.divisionReducer);
        const navigate = useNavigate();
        const dispatch = useAppDispatch();
        const {confirmDialog, onHide} = useConfirmDialog();
        const [state, setState] = useState<LayoutState>({
            checkAuth: null,
            isLoading: true
        });

        useLayoutEffect(() => {
            AuthService.getUserInfo2().then(resp => {
                if (resp?.status === 200 && resp.body) {
                    setState({isLoading: false, checkAuth: null});
                    dispatch(setUserInfo(resp.body));
                    dispatch(setPersonalNumber(resp.body.personnelNumber));
                    const {isTemporaryPassword, name, username, personnelNumber} = resp.body;
                    console.log(resp.body, currentDivisionRef);
                    if (isTemporaryPassword && (name || username) && personnelNumber) {

                        // if (!!currentDivisionRef)
                        //     ClientProfileService.getProfile(personnelNumber).then((resp) => {
                        //         dispatch(setDivisions(resp.body.divisions.filter(d => d.active)));
                        //     });

                        if (currentDivisionRef)
                            setTimeout(() => {
                                confirmDialog({
                                    title: "Сменить временный пароль",
                                    message: "",
                                    acceptLabel: "Сменить",
                                    isOpen: true,
                                    accept: async () => {
                                        navigate(`/auth-reset?divisionRef=${currentDivisionRef}&username=${username || name}&personnelNumber=${personnelNumber}`);
                                    },
                                    reject: async () => {
                                    }
                                });
                            }, 5000);
                    }
                }
            }).catch(e => {
                setState(prevState => ({isLoading: false, checkAuth: "Не удалось загрузить страницу"}));
            });
        }, [currentDivisionRef]);

        return (
            <>
                {
                    state.isLoading ? <FullPageLoading/> :
                        (state.checkAuth &&
                            <Center>
                                <ErrorBlock error={state.checkAuth}/>
                            </Center>) || <>
                            <Container className="mainContainer">
                                <InfoCartBanner/>
                                <Toolbar
                                    rightElements={<RightMenu id="menu" pages={pages} placement={"end"} name={"menu"}/>}
                                    leftElements={<ToolbarMenu pages={pages}/>}/>
                                <Outlet/>
                            </Container>
                            <footer className="appFooter">
                                <NavLink to="contacts">Контакты</NavLink>
                                <NavLink to="help#about">Вопросы и ответы</NavLink>
                            </footer>
                        </>
                }
            </>
        );
    }
;

export default Layout;
