import React, {FC, useEffect, useState} from 'react';
import "./style.scss";
import Select, {FormatOptionLabelMeta, SingleValue} from "react-select";
import {ActionMeta} from "react-select/dist/declarations/src";

export interface IOptionSelect {
    label: string
    value: string | undefined
}

export interface LoadableSelectProps {
    value?: string;
    tag?: string
    label: string;
    loading: boolean;
    onChange: (option: SingleValue<IOptionSelect>) => void
    options: IOptionSelect[];
    renderOption?: (data: IOptionSelect, formatOptionLabelMeta: FormatOptionLabelMeta<IOptionSelect>) => React.ReactNode
    error: string;
    placeholder?: string
    isRequiredField?: boolean
    className?: string | undefined
}

export const styleOptions = {
    option: (provided: any, state: any) => {
        let color = state.isSelected ? 'var(--primary)' : 'var(--text-primary)'
        let backgroundColor = '#fff'

        if (state.isFocused)
            backgroundColor = 'rgba(0,0,0,0.05)'

        if (state.isSelected) {
            color = 'var(--primary)'
            backgroundColor = '#f8fcff'
        }

        return {
            ...provided,
            color,
            cursor: 'pointer',
            backgroundColor
        }
    },
    control: (provided: any, state: any) => ({...provided, borderRadius: 'var(--br)', cursor: 'pointer', "&:hover": {borderColor: 'var(--primary)'}}),
    singleValue: (provided: any, state: any) => ({...provided, padding: '0 2px', marginTop: 2, cursor: 'pointer'}),
    menu: (provided: any, state: any) => ({...provided, zIndex: 7}),
    dropdownIndicator: (provided: any, state: any) => {
        let color = 'var(--primary)'

        if (state.isFocused)
            color = 'var(--primary)'

        return {
            ...provided,
            cursor: 'pointer',
            color
        }
    }
}

const LoadableSelect: FC<LoadableSelectProps> = ({
                                                     value,
                                                     options,
                                                     label,
                                                     loading,
                                                     tag,
                                                     isRequiredField = false,
                                                     className,
                                                     error,
                                                     onChange,
                                                     placeholder,
                                                     renderOption,
                                                     ...props
                                                 }) => {
    const [selected, setSelected] = useState<SingleValue<IOptionSelect> | undefined>(undefined)

    useEffect(() => {
        setSelected(options.find(o => o.value === value))
    }, [options])

    const renderPlaceholder = () => {
        if (!loading && !error && options.length === 0)
            return "Нет меню"

        if (loading || error)
            return (loading && "Загрузка...") || (error && "Ошибка загрузки " + error)

        return placeholder
    }

    return (
        <div className={className}>
            <label className={`text-center${isRequiredField ? ' requiredField' : ''}`} htmlFor="place">{label}</label>
            <Select key={`react-select-${selected?.value}`} className="select" placeholder={renderPlaceholder()}
                    options={options}
                    formatOptionLabel={renderOption ? renderOption : this}
                    value={selected}
                    aria-errormessage={"Error"}
                    styles={styleOptions}
                    isSearchable={false}
                    isDisabled={loading}
                    isLoading={loading}
                    noOptionsMessage={() => "Нет вариантов"}
                    onChange={(newValue: SingleValue<IOptionSelect>, actionMeta: ActionMeta<IOptionSelect>) => {
                        if (selected !== newValue)
                            onChange(newValue)
                    }}/>
        </div>
    )
}

export default LoadableSelect;
