import React, {lazy, Suspense} from "react";
import "./css/global.scss";
import {Route, Routes} from "react-router-dom";
import {Page} from "./common/types";
import Layout from "./layout/Layout";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import AuthBaseRedirect from "./pages/auth/AuthBaseRedirect";
import {
    BagFill,
    ClipboardCheckFill,
    CupHotFill, Cart4,
    PersonFill, QuestionCircleFill,
    TelephoneFill, BoxArrowRight
} from "react-bootstrap-icons";
import Design from './pages/design/Design';
import Providers from './providers/providers';

const Profile = lazy(() => import("./pages/profile/Profile"));
const Catalog = lazy(() => import("./pages/catalog/Catalog"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const Carts = lazy(() => import("./pages/carts/Carts"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const Logout = lazy(() => import("./pages/Logout"));
const Order = lazy(() => import("./pages/order/Order"));
const ContactsPage = lazy(() => import( "./pages/contacts/ContactsPage"));
const HelpPage = lazy(() => import( "./pages/help/HelpPage"));
const AuthPage = lazy(() => import( "./pages/auth/AuthPage"));
const AuthAdmin = lazy(() => import( "./pages/auth/AuthAdmin"));
const AuthBase = lazy(() => import( "./pages/auth/AuthBase"));
const RedirectPage = lazy(() => import( "./pages/auth/RedirectPage"));

const pages: Page[] = [
    {
        id: 0,
        path: "catalog",
        name: "Меню",
        element: <Catalog/>,
        icon: <CupHotFill/>
    },
    {
        id: 1,
        path: "orders",
        name: "Заказы",
        element: <Orders/>,
        icon: <ClipboardCheckFill/>
    },
    {
        id: 2,
        path: "contacts",
        name: "Контакты",
        element: <ContactsPage/>,
        icon: <TelephoneFill/>
    },
    {
        id: 3,
        path: "profile",
        name: "Личный кабинет",
        element: <Profile/>,
        icon: <PersonFill/>
    },
    {
        id: 8,
        path: "orders/:divisionId/:id",
        name: "Заказ",
        element: <Order/>,
        icon: <ClipboardCheckFill/>,
        notInMenu: true
    },
    {
        id: 4,
        path: "cart",
        isBase: true,
        name: "Корзина",
        element: <Carts/>,
        icon: <Cart4/>
    },
    {
        id: 5,
        path: "logout",
        name: "Выход",
        notInMenu: true,
        element: <Logout/>,
        icon: <BoxArrowRight/>
    },
    {
        id: 6,
        path: "help",
        name: "Справка",
        notInMenu: true,
        element: <HelpPage/>,
        icon: <QuestionCircleFill/>
    },
    {
        id: 7,
        path: "design",
        name: "Дизайн",
        notInMenu: true,
        element: <Design/>,
        icon: <QuestionCircleFill/>
    }
];

function App() {
    return (
        <Providers>
            <Suspense>
                <Routes>
                    <Route path="/" element={<Layout pages={pages}/>}>
                        <Route index element={<Catalog/>}/>
                        {
                            pages.map(p => {
                                return <Route key={p.path} path={p.path} element={p.element}/>;
                            })
                        }
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                    <Route path="auth" element={<AuthPage/>}>
                        <Route index element={<AuthBase/>}/>
                        <Route path="redirect" element={<RedirectPage/>}/>
                        <Route path="secure-auth" element={<AuthAdmin/>}/>
                        <Route path="auth-redirect-to-reset" element={<AuthBaseRedirect/>}/>
                    </Route>
                    <Route path="auth-reset" element={<ResetPasswordPage/>}/>
                </Routes>
            </Suspense>
        </Providers>
    );
}

export default App;
