import React, {FC, useState} from 'react';
import {Form} from 'react-bootstrap';
import LoadingButton from "../../components/buttons/loadingButton/LoadingButton";
import Loader from "../../components/UI/loader/Loader";
import './auth.scss';
import AuthService from "../../entity/auth/AuthService";
import LocalStorageService from "../../entity/storage/LocalStorageService";
import useTokens from "../../hooks/useTokens";
import {useLocation, useNavigate} from "react-router-dom";
import LogoForm from "./LogoForm";
import ClientService from "../../entity/client/ClientService";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
    BroadcastStateClientProfile,
    LocationState,
    LocationStateSearch,
    RecoveryPasswordState
} from '../../common/types';
import useLocationParams from '../../hooks/useLocationParams';
import {resetUserInfoData} from '../../redux/reducers/profileSlice';

interface ResetPasswordPageProps {

}

interface IResetPasswordForm {
    current: string;
    newPassword: string;
    confirmPassword: string;
}

const resetPasswordSchema = Yup.object().shape({
    current: Yup.string().required('Пароль не должен быть пустым').nullable(),
    newPassword: Yup.string().required('Пароль не должен быть пустым').min(7,
        'Пароль должен быть больше 7 символов')
        .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/,
            'В пароле дложны быть цифры, строчные и прописные символы').nullable(),
    confirmPassword: Yup.string().required('Пароль не должен быть пустым').min(7,
        'Пароль должен быть больше 7 символов')
        .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/,
            'В пароле дложны быть цифры, строчные и прописные символы').nullable()
});

const ResetPasswordPage: FC = () => {
    const locationState: RecoveryPasswordState = useLocationParams<RecoveryPasswordState>()
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const formik = useFormik<IResetPasswordForm>({
        initialValues: {
            current: '',
            newPassword: '',
            confirmPassword: ''
        },
        validate: (values) => {
        },
        initialErrors: {
            current: "",
            newPassword: "",
            confirmPassword: ""
        },
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, formikHelpers) => {
            console.log(values);

        }
    });
    const {setTokens} = useTokens();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const confirmPasswordHandle = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const {current, newPassword, confirmPassword} = formik.values;
        const {divisionRef, personnelNumber, username} = locationState;


        if (current && newPassword && confirmPassword && !newPassword.localeCompare(confirmPassword) && divisionRef) {
            setIsLoading(true);
            setError('');

            return await Promise.all([
                AuthService.resetPassword({currentPassword: current, newPassword}),
                ClientService.setPasswordClient(divisionRef, personnelNumber, {
                    username, password: newPassword
                })
            ]).then(() => {
                AuthService.logIn({login: personnelNumber, password: newPassword, personnelNumber})
                    .then((resp) => {
                        if (resp.body) {
                            const {refreshToken, token, personnelNumber} = resp.body;

                            if (!personnelNumber) {
                                setError("Не найден табельный номер. Обратитесь к администратору.");
                            }

                            if (token && refreshToken && personnelNumber) {
                                LocalStorageService.setValue('personnelNumber', personnelNumber);
                                setTokens({refreshToken, token});
                                navigate('/');
                            }
                        }
                    }).catch(() => {
                    setError('Не удалось поменять пароль');
                }).finally(()  => {
                    setIsLoading(false);
                    formik.resetForm();
                });
            }).catch(() => {
                formik.resetForm();
                setError('Не удалось создать пароль');
            })
        } else {
            setError('Пароль не совпадает или заполните поля');
        }
    };

    return (
        <>
            <div className="authContainer">
                <div className="wrap">
                    <div>
                        {/*<Image className="logoCompany" src={logo}/>*/}
                        <LogoForm/>
                    </div>
                    <br/>
                    <h3>Изменить пароль</h3>
                    {
                        isLoading ? <Loader/> : <Form className="form">
                            <Form.Group className="mb-3" controlId="reset-cur-pass">
                                <Form.Label>Введите текущий пароль</Form.Label>
                                <Form.Control type="password" placeholder="Введите пароль" value={formik.values.current}
                                              name="current"
                                              onChange={formik.handleChange}/>
                                <p className="text-center errorValidateBlock">
                                    {formik.errors.current && formik.errors.current}
                                </p>
                            </Form.Group>
                            <hr/>
                            <Form.Group className="mb-3" controlId="reset-first-pass">
                                <Form.Label>Введите новый пароль</Form.Label>
                                <Form.Control type="password" placeholder="Введите пароль" name="newPassword"
                                              value={formik.values.newPassword}
                                              onChange={formik.handleChange}/>
                                <p className="text-center errorValidateBlock">
                                    {formik.errors.newPassword && formik.errors.newPassword}
                                </p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="reset-last-pass">
                                <Form.Label>Повторите новый пароль</Form.Label>
                                <Form.Control type="password" placeholder="Повторите пароль" name="confirmPassword"
                                              value={formik.values.confirmPassword}
                                              onChange={formik.handleChange}/>
                                <p className="text-center errorValidateBlock">
                                    {formik.errors.confirmPassword && formik.errors.confirmPassword}
                                </p>
                            </Form.Group>
                            <LoadingButton type={'submit'} classes={['primary']} title={'Подтвердить'}
                                           titleProcessing={'Подтвердить'}
                                           onClick={confirmPasswordHandle}
                                           disabled={!formik.isValid}/>
                        </Form>
                    }
                    {
                        error && <p className="text-center errorValidateBlock">{error}</p>
                    }
                </div>
            </div>
        </>
    );
};

export default ResetPasswordPage;