import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import "./banner.scss"
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {NavLink} from "react-router-dom";
import {XCircleFill} from "react-bootstrap-icons";
import {setShowBanner} from "../../redux/reducers/cartSlice";
import {MINUTE, SECOND, SHOW_FOR_TIME_MS} from "../../utils/DateUtils";

const InfoCartBanner: FC = () => {
    const dispatch = useAppDispatch()
    const {cart, isShowBanner} = useAppSelector(state => state.cartReducer)
    const refTimer = useRef<any>()
    const [timer, setTimer] = useState<number>(0)

    const timeMemo = useMemo(() => {
        let time = 0

        if (cart && cart.items.length && cart.allowed) {
            const allowedUntil = cart.allowedUntil
            const now = new Date()
            const timeTmp = allowedUntil.getTime() - now.getTime()

            if (timeTmp > 0 && timeTmp <= SHOW_FOR_TIME_MS)
                time = timeTmp
        }

        return time
    }, [cart]);

    useEffect(() => {
        //TODO возможно не лучшее решение
        if (timeMemo > 0 && timeMemo <= SHOW_FOR_TIME_MS) {
            setTimer(timeMemo)

            dispatch(setShowBanner(true))

            refTimer.current = setInterval(() => {
                setTimer(prevState => {
                    if (prevState <= 0) {
                        clearInterval(refTimer.current)
                        return 0
                    }

                    return prevState - SECOND
                })
            }, SECOND)
        }

        return () => {
            if (refTimer.current)
                clearInterval(refTimer.current)
        }
    }, [timeMemo])

    if (!cart || !timer || !isShowBanner)
        return null

    const closeHandle = () => {
        setTimer(0)
        dispatch(setShowBanner(false))
    }

    return (
        <div className={`bannerWrap ${timer <= 0 ? ' hideBanner' : ''}`}>
            <NavLink to={'cart'} onClick={closeHandle}>
                <XCircleFill className="close" onClick={closeHandle}/>
                <div className="banner">
                    <section>
                        <div>
                            <strong>{cart.divisionName} {cart.cartDate} {cart.mealName}</strong>
                            <p className="helpers">Время оформления заказа истекает</p>
                        </div>
                    </section>
                    <div className="delayBlock">
                        <CircularProgressbarWithChildren
                            className={`circular ${timer > 0 ? 'pinPongAnim' : 'endTimerAnim'}`}
                            maxValue={SHOW_FOR_TIME_MS}
                            minValue={0} value={timer}
                            styles={buildStyles({
                                textSize: 24, pathColor: 'var(--primary)', textColor: 'var(--danger)'
                            })}>
                            <div className="textDisplay">
                                <small className="counter">
                                    {
                                        `${(timer / MINUTE).toFixed(1)}`
                                    }
                                </small>
                                <small className="minute">мин</small>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}

export default React.memo(InfoCartBanner);
