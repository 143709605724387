import AbstractService from "../../common/AbstractService";
import CartDto from "./CartDto";
import ListApiResponse from "../../common/dto/ListApiResponse";
import EntityApiResponse from "../../common/dto/EntityApiResponse";
import CartItemIncoming from "./CartItemIncoming";
import ApiResponse from "../../common/dto/ApiResponse";
import {api} from "../../api/axiosInstances";

export default class CartService extends AbstractService {

    static async getAllCart(divisionId: string, personnelNumber: string): Promise<ListApiResponse<CartDto>> {
        const response = await api.get<ListApiResponse<CartDto>>
        ('/cart/', {
            params: {
                divisionId: divisionId,
                personnelNumber: personnelNumber
            }
        });
        return response.data;
    }

    static async cart(date: string, mealId: string, divisionId: string, personnelNumber: string, signal?: AbortSignal): Promise<EntityApiResponse<CartDto>> {
        const response = await api.get<EntityApiResponse<CartDto>>(`/cart/${date}/${mealId}/`, {
            params: {
                divisionId: divisionId,
                personnelNumber: personnelNumber
            }, signal: signal
        });
        return response.data;
    }

    static async addToCart(date: string, mealId: string, divisionId: string, personnelNumber: string, item: CartItemIncoming): Promise<EntityApiResponse<CartDto>> {
        const response = await api.post<EntityApiResponse<CartDto>>
        (`/cart/${date}/${mealId}/items/`,
            item,
            {
                params: {
                    divisionId: divisionId,
                    personnelNumber: personnelNumber
                }
            }).catch((e) => e.response);
        return response.data;
    }

    static async deleteFromCart(date: string, mealId: string, itemId: string, divisionId: string,
                                personnelNumber: string): Promise<EntityApiResponse<CartDto>> {
        const response = await api.delete<EntityApiResponse<CartDto>>
        (`/cart/${date}/${mealId}/items/${itemId}/`,
            {
                params: {
                    divisionId: divisionId,
                    personnelNumber: personnelNumber
                }
            });
        return response.data;
    }

    static async editCartItem(date: string, mealId: string, divisionId: string, personnelNumber: string,
                              item: CartItemIncoming): Promise<EntityApiResponse<CartDto>> {
        const response = await api.put<EntityApiResponse<CartDto>>
        (`/cart/${date}/${mealId}/items/${item.ref}/`,
            item,
            {
                params: {
                    divisionId: divisionId,
                    personnelNumber: personnelNumber
                }
            });
        return response.data;
    }

    static async deleteCart(date: string, mealId: string, divisionId: string,
                            personnelNumber: string): Promise<ApiResponse> {
        const response = await api.delete<EntityApiResponse<CartDto>>
        (`/cart/${date}/${mealId}/`,
            {
                params: {
                    divisionId: divisionId,
                    personnelNumber: personnelNumber
                }
            });
        return response.data;
    }

}
