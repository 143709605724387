import {useEffect, useState} from 'react';
import { setTokensLocalStorage } from '../api/axiosInstances';
import {ITokens} from "../entity/auth/AuthDto";
import {getAsyncTokensLocalStorage} from "../entity/auth/AuthService";

interface UseTokensReturn {
    tokens: ITokens | undefined,
    setTokens: (tokens: ITokens) => void
}

const useTokens = (): UseTokensReturn => {
    const [tokens, setTokens] = useState<ITokens>()

    useEffect(() => {
        (async () => {
            const localTokens = await getAsyncTokensLocalStorage()
            setTokens(localTokens)
        })()
    }, [])

    const saveTokens = async (tokens: ITokens) => {
        await setTokensLocalStorage(tokens)
        setTokens(tokens)
    }

    return {setTokens: saveTokens, tokens}
}

export default useTokens;
