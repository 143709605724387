import {useLocation} from 'react-router-dom';

interface UseLocationParamsProps {
    hash?: boolean;
}

const useLocationParams = <T extends Record<string, string>>(hash = false): T => {
        const {search, state} = useLocation();
        let params = [];

        if (hash) {
            let decodeParams = atob(search.replace('?', ''));
            params = decodeParams.split('&');
        } else
            params = search.replace('?', '').split('&');

        const result = Array.from(params.entries()).reduce((acc, [_, val]) => {
            const [key, value] = val.split('=');
            // @ts-ignore
            acc[key] = value;
            return acc;
        }, {} as T);

        return {...result, ...state};
    }
;

export default useLocationParams;
