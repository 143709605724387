import React, {FC, useCallback, useMemo} from 'react';
import './menu.scss';
import {NavLink} from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import {clearTokensLocalStorage} from "../../api/axiosInstances";
import {Page} from "../../common/types";
import Notifications from '../notifications/Notifications';
import CartMenu from "./CartMenu";
import ExitIcon from "../../assets/icons/logout.svg";
import {BoxArrowRight} from 'react-bootstrap-icons';

interface ToolbarMenuProps {
    pages: Page[];
}

const ToolbarMenu: FC<ToolbarMenuProps> = ({pages}) => {
    const logoutHandle = useCallback(() => {
        clearTokensLocalStorage()
        window.location.replace('/auth')
    }, [])

    const sortPages = useMemo(() => pages.filter(i => (!i.notInMenu && !i.isBase))
        .sort((a, b) => a.id - b.id), [])

    const cartsMenu = useMemo(() => pages.find(p => p.path.includes('cart')), []);

    return (
        <>
            <div className="toolbarMenu">
                {
                    sortPages.map(p => {
                        if (p.path.includes("profile"))
                            return <NavDropdown key={p.path} className="link exp"
                                                title={(<div className="link ps-0">
                                                    {
                                                        p.icon
                                                    }
                                                    <span className="wrapTitle">Профиль</span>
                                                </div>)}>

                                <NavDropdown.Item href="#">
                                    <NavLink className="link option" to="/profile">
                                        {
                                            p.icon
                                        }
                                        <span className="wrapTitle">{p.name}</span>
                                    </NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={logoutHandle}>
                                    <div className="link option">
                                        <BoxArrowRight/>
                                        <span className="wrapTitle">Выход</span>
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>

                        if (p.path.includes('catalog')) {
                            const path = p.path + '#divider-main-banner'
                            const homeClass = window.location.pathname === "/" ? " active" :
                                window.location.pathname === path ? ' active' : '';

                            return <NavLink key={p.path} className={`link${homeClass}`} to={path}>
                                {
                                    p.icon
                                }
                                <span className="wrapTitle">{p.name}</span>
                            </NavLink>;
                        }

                        if (p.path.includes('contacts'))
                            return <NavLink key={p.path} className="link contacts-menu" to={p.path + '#divider-main-banner'}>
                                {
                                    p.icon
                                }
                                <span className="wrapTitle">{p.name}</span>
                            </NavLink>

                        return <NavLink key={p.path} className="link" to={p.path}>
                            {
                                p.icon
                            }
                            <span className="wrapTitle">{p.name}</span>
                        </NavLink>
                    })
                }
            </div>
            <div className="baseMenu">
                {
                    cartsMenu && <CartMenu key={cartsMenu.path} page={cartsMenu}/>
                }
                <Notifications/>
            </div>
        </>
    );
}

export default ToolbarMenu;
