import React, {FC, ReactNode} from 'react';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import ConfirmDialogProvider from '../components/dialogs/ConfirmDialogContext';

interface ProvidersProps {
    children: ReactNode;
}

const Providers: FC<ProvidersProps> = ({children}) => {
    return (
        <ConfirmDialogProvider>
            {children}
            <ConfirmDialog/>
        </ConfirmDialogProvider>
    );
};

export default Providers;