import {createSlice} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"
import {AbstractSate} from "./AbstractSate";
import {IAvailableAuthTypes} from "../../entity/auth/AuthDto";
import {availableAuthTypes} from "../actions/authAsyncActions";

enableMapSet()

const name = 'auth'

interface AuthState extends AbstractSate {
    availableTypes?: IAvailableAuthTypes
}

const initialState: AuthState = {
    isLoading: true,
    availableTypes: undefined,
    error: null
}

export const authSlice = createSlice({
    name,
    initialState,
    reducers: {
        setAvailableTypes: (state, action) => {
            state.availableTypes = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(availableAuthTypes.pending, ((state, action) => {
            state.isLoading = true
            state.error = ""
        })).addCase(availableAuthTypes.fulfilled,
            ((state, action) => {
            state.availableTypes = action.payload
            state.isLoading = false
            state.error = ""
        })).addCase(availableAuthTypes.rejected, ((state, action) => {
            state.error = "Сервер не отвечает. Обновите страницу"
            state.isLoading = false
        }))
    }
})

export default authSlice.reducer

