import AbstractService from "../../common/AbstractService";
import EntityApiResponse from "../../common/dto/EntityApiResponse";
import ClientForProfileDto from "./ClientForProfileDto";
import ClientDto from "./ClientDto";
import ClientSetContactInformationDto from "./ClientSetContactInformationDto";
import AvailableBenefitDto, {IRequestBenefitsReportData} from "./AvailableBenefitDto";
import {api} from "../../api/axiosInstances";
import ClientSetAuthDto from "./ClientSetContactInformationDto";

export default class ClientService extends AbstractService {
    static async getClientForProfile(divisionId: string, personnelNumber: string): Promise<EntityApiResponse<ClientForProfileDto>> {
        const response = await api.get<EntityApiResponse<ClientForProfileDto>>
        (`/clients/${personnelNumber}/profile/`,
            {
                params: {
                    divisionId: divisionId
                }
            });
        return response.data;
    }

    static async setContactInformation(divisionId: string, personnelNumber: string, contactInformation:
        ClientSetContactInformationDto): Promise<EntityApiResponse<ClientDto>> {
        const response = await api.patch<EntityApiResponse<ClientDto>>(`/clients/byPersonnelNumber/${personnelNumber}`,
            contactInformation,
            {
                params: {
                    divisionId: divisionId
                }
            });
        return response.data;
    }

    static async setPasswordClient(divisionId: string, personnelNumber: string, data: ClientSetAuthDto): Promise<EntityApiResponse<ClientDto>> {
        const response = await api.patch<EntityApiResponse<ClientDto>>(`/clients/setPassword/${personnelNumber}`,
            data,
            {
                params: {
                    divisionId: divisionId
                }
            });
        return response.data;
    }

    static async getBenefitsReport(divisionId: string, personnelNumber: string, data: IRequestBenefitsReportData):
        Promise<EntityApiResponse<ClientDto>> {
        const response = await api.post<EntityApiResponse<ClientDto>>(`/clients/${personnelNumber}/benefitsReportRequest`,
            data,
            {
                params: {
                    divisionId: divisionId
                }
            });
        return response.data;
    }

    static async getAvailableBenefits(divisionId: string, personnelNumber: string, date: string):
        Promise<EntityApiResponse<AvailableBenefitDto[]>> {
        const response = await api.get<EntityApiResponse<AvailableBenefitDto[]>>(`/clients/${personnelNumber}/availableBenefits/${date}`,
            {
                params: {
                    divisionId: divisionId
                }
            });
        return response.data;
    }
}
